import React, { useState, useEffect } from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'
import Helmet from "react-helmet"
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner';

function PublicStore() {
    return (
        <Layout>
            <Helmet>
                <script type="text/javascript">{`
                    window.document.addEventListener('vinoshipper:loaded', () => {
                        window.Vinoshipper.init(3535, {});
                    }, false);
                    `}
                </script>
                <script src="https://vinoshipper.com/injector/index.js"></script>
            </Helmet>
            <SEO title="Public Store" />
            <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
                <header className="Header Header--bottom Header--overlay">
                    <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                        <div data-nc-container="bottom-left">
                        </div>
                        <div data-nc-container="bottom-center">
                        </div>
                        <div data-nc-container="bottom-right">
                            <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                                data-content-field="navigation">
                            </nav>
                        </div>
                    </div>
                </header>
                <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
                    <main className="Index" data-collection-id="5b160901758d4658e6571eef" data-controller="IndexFirstSectionHeight, Parallax, IndexNavigation"
                        data-controllers-bound="IndexFirstSectionHeight, Parallax, IndexNavigation">
                        <section id="contact-us" className="Index-page" data-collection-id="5ad765962b6a288cd1072c53"
                            data-parallax-id="5ad765962b6a288cd1072c53" data-edit-main-image="Background">
                            <div className="Index-page-content" id="content">
                                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528241759086"
                                    id="pasge-5ad765962b6a288cd1072c53">
                                    <div className="row sqs-row">
                                        <div className="col sqs-col-12 span-12">
                                            <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1528212858018_25259">
                                                <div className="sqs-block-content">
                                                    <h3 className="text-align-left"><strong>Anderson Family Vineyard Friends Wine Club</strong></h3>
                                                    <p className="text-align-left"><a href="https://friends.andersonfamilyvineyard.com/new/0">Click here</a> to get more details and join the Anderson Family Wine Club</p>
                                                    <h3 className="text-align-left"><strong>Current Wine Club Members</strong></h3>
                                                    <p className="text-align-left"><a href="https://friends.andersonfamilyvineyard.com/login">Login</a> to our website and update your account information or make an online wine purchase<br /></p>
                                                    <h3 className="text-align-left"><strong>Retail Customers</strong></h3>
                                                    <p className="text-align-left">Please purchase your Anderson Family wines with our Retail shopping cart below</p>
                                                    <br />
                                                    <div class="vs-products"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </Layout >
    );
}

export default PublicStore;